import React, { createContext, useContext, useState, useEffect } from "react";
import { firestore } from "../firebase";
import { UserContext } from "./UserProvider";

export const RequestsContext = createContext();
const RequestsProvider = ({ children }) => {
  const user = useContext(UserContext);
  const [requests, setRequests] = useState([]);
  useEffect(() => {
    if (user) {
      const unsubscribe = firestore
        .collection("requests")
        .where("client", "==", user.uid)
        .onSnapshot(snapshot => {
          const requests = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setRequests(() => {
            return requests;
          });
        });
      return () => {
        unsubscribe();
      };
    }
  }, [user]);
  return (
    <RequestsContext.Provider value={requests}>
      {children}
    </RequestsContext.Provider>
  );
};

export default RequestsProvider;
