import React, { useState, useContext, useEffect } from "react";
import { Link, navigate } from "@reach/router";
import { auth, provider } from "./firebase";
import { toast } from "react-toastify";
import logo from "./images/logo.png";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "./providers/UserProvider";

toast.configure();
const SignIn = () => {
  const user = useContext(UserContext);
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  const toastError = () =>
    toast("Incorrect email/password", {
      className: "toast-background rounded",
      progressClassName: "toast-progress"
    });
  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, [user]);

  async function signInWithEmailAndPassword(email, password) {
    auth.signInWithEmailAndPassword(email, password).catch(() => {
      toastError();
    });
  }

  async function signInWithGoogle() {
    auth.signInWithPopup(provider).catch(() => {
      toastError();
    });
  }
  return (
    <div className="full-page">
      <div className="container-fluid d-flex h-100">
        <div className="row m-auto overflow-hidden">
          <h1 className="w-100 text-center">
            <img className="logo-auth mt-3" src={logo} alt="logo" />
          </h1>
          <div id="request" className="card shadow mx-3 m-auto auth-card">
            <div className="card-body">
              <div className="row width-20">
                <h4 className="card-title font-weight-bold col-6 mw-100">
                  Sign In
                </h4>
              </div>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  signInWithEmailAndPassword(email, password);
                }}
              >
                <div className="form-row">
                  <div className="form-group col-12">
                    <label htmlFor="inputEmail4" className="font-weight-bold">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                      placeholder="Email"
                      value={email}
                      onChange={e => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-12">
                    <label
                      htmlFor="inputPassword4"
                      className="font-weight-bold"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="inputPassword4"
                      placeholder="Password"
                      value={password}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-row text-right">
                  <p className="font-weight-bold w-100">
                    <a href="#forgot-pass">Forgot your password?</a>
                  </p>
                </div>
                <button className="btn btn-primary w-100">Submit</button>
              </form>
              <button
                className="btn btn-outline-dark w-100 mt-2"
                onClick={signInWithGoogle}
              >
                <i className="fab fa-google mr-2"></i> Sign in with Google
              </button>
              <p className="text-center mt-2 hint">
                Not a member?<Link to="/sign-up"> Sign up</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
