import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import firebaseConfig from "../firebaseConfig";
import axios from "axios";

axios.interceptors.request.use(
  async config => {
    if (firebase.auth().currentUser) {
      let token = await firebase.auth().currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      return config;
    }
  },
  error => {
    return Promise.reject(error);
  }
);
firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
export const firestore = firebase.firestore();
export const FieldValue = firebase.firestore.FieldValue;
export const getUserDocument = async uid => {
  if (!uid) return null;
  try {
    return firestore.collection("users").doc(uid);
  } catch (error) {
    return null;
  }
};
