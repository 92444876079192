import React, { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { Redirect } from "@reach/router";

export const AuthenticatedRoute = ({ children }) => {
  const user = useContext(UserContext);

  if (!user) return <Redirect to="/" noThrow />;
  return children;
};
