import axios from "axios";
import { storeObjectWithId } from "./db";
import config from "../../firebaseConfig";

export const initializeCustomer = async (name, uid) => {
  try {
    const created = await storeObjectWithId("customers", uid, { name });
    return created;
  } catch (error) {
    console.error(error);
  }
};
export const generateMagicLink = async () => {
  return axios
    .post(
      `${config.apiUrl}/billingFunctions-generateInvoicedLink`
    )
    .then(response => {
      return response.data;
    })
    .catch(() => {
      return "";
    });
};
