import React, { useState, useContext, useEffect, useRef } from "react";
import { RequestsContext } from "../providers/RequestsProviderFunctional";
import { Link, navigate } from "@reach/router";
import { UserContext } from "../providers/UserProvider";
import statusMessages from "../StatusMessages";
import Modal from "react-modal";
import { firestore, FieldValue } from "../firebase";
const Requests = () => {
  const user = useContext(UserContext);
  if (!user) navigate("/");
  let requestList = useContext(RequestsContext);
  let loading = useRef(true);
  if (requestList) {
    loading.current = false;
  }

  useEffect(() => {
    if (requestList) {
      loading.current = false;
    }
  }, [requestList]);
  let [selected, setSelected] = useState(false);
  let [recommendations, setRecommendations] = useState({});
  let [rindex, setRindex] = useState(0);
  let [currentRecommendation, setCurrentRecommendation] = useState(null);
  useEffect(() => {
    if (!selected) return;
    selected.recommendations.forEach(profileId => {
      firestore
        .collection("teamoProfiles")
        .doc(profileId)
        .get()
        .then(doc => {
          if (doc.exists) {
            recommendations[doc.id] = doc.data();
            setRecommendations(() => {
              setCurrentRecommendation(
                recommendations[selected.recommendations[0]]
              );
              return recommendations;
            });
          }
        });
    });
  }, [selected, recommendations]);
  let [modalIsOpen, setIsOpen] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const acceptResource = id => {
    setRecommendations(() => {
      delete recommendations[id];
      return recommendations;
    });
    setIsOpen(false);
    setSelected(() => {
      selected.recommendations = selected.recommendations.filter(p => p !== id);
      setCurrentRecommendation(selected.recommendations[0] || null);
      return selected;
    });
    firestore
      .collection("requests")
      .doc(selected.id)
      .update({
        recommendations: FieldValue.arrayRemove(id),
        team: FieldValue.arrayUnion(id)
      });
  };
  const rejectResource = id => {
    firestore
      .collection("requests")
      .doc(selected.id)
      .update({
        recommendations: FieldValue.arrayRemove(id)
      });
  };
  return (
    <>
      <div
        id="select-request"
        className="card shadow mx-3 m-auto request-card"
        hidden={selected}
      >
        {loading.current ? (
          <div className="card-body">
            <h3 className="card-title font-weight-bold col-6 mw-100">
              Loading...
            </h3>
          </div>
        ) : (
          <div className="card-body">
            <h3 className="card-title font-weight-bold col-6 mw-100">
              Your Requests
            </h3>
            <hr />
            <div className="row">
              <div className="col-2 font-weight-bold">#</div>
              <div className="col-4 font-weight-bold">Status</div>
              <div className="col-3"></div>
            </div>
            <hr />
            {requestList.length === 0 ? (
              <p>*Click on the button below to submit a request</p>
            ) : requestList.map(request => (
              <div key={request.id}>
                <div className="row pb-2">
                  <div className="col-3">{request.rid}</div>
                  <div className="col-6 col-md-6 request-status">
                    {request.status}
                  </div>
                  <div className="col-3 m-auto d-block d-sm-none">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        setSelected(
                          requestList.filter(req => req.id === request.id)[0]
                        );
                      }}
                    >
                      <i className="fa fa-caret-right"></i>
                    </button>
                  </div>
                  <div className="col-3 m-auto d-none d-sm-block">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        setSelected(
                          requestList.filter(req => req.id === request.id)[0]
                        );
                      }}
                    >
                      Details
                    </button>
                  </div>
                </div>
                <hr />
              </div>
            ))}
            <Link
              to="/submit-request"
              className="btn btn-outline-dark mt-3 w-100"
            >
              Submit New Request
            </Link>
          </div>
        )}
      </div>
      <div
        id="request"
        className="card shadow mx-3 m-auto request-card"
        hidden={!selected}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-md-6">
              <h3 className="card-title font-weight-bold col-6 mw-100">
                Request #{selected.rid}
              </h3>
            </div>
            <div className="col-md-3 d-lg-block"></div>
            <div className="col-12 col-md-3 text-right">
              <button
                id="show-requests"
                className="btn btn-outline-dark w-100"
                onClick={() => {
                  setSelected(false);
                }}
              >
                Back
              </button>
            </div>
          </div>
          <hr />
          <p className="card-subtitle mb-2 text-muted">
            Status:{" "}
            <span className="status request-status">{selected.status}</span>
          </p>
          <p className="card-text">{statusMessages[selected.status]}</p>
          <hr />
          <div className="row">
            <div className="col-6 border-right border-left">
              <p className="card-text">
                You requested{" "}
                <strong>
                  {selected.resourceType &&
                    selected.resourceType.reduce(
                      (total, value) => total + Number(value.number),
                      0
                    )}
                </strong>{" "}
                resources.
                <br />
                The following types were specified:
              </p>
              <ol>
                {selected.resourceType &&
                  selected.resourceType.map((resource, index) => (
                    <li key={index}>
                      {resource.resource} - {resource.experience}
                    </li>
                  ))}
              </ol>
            </div>
            <div className="col-6 border-right border-left">
              <p className="card-text">Your technology stack:</p>
              <ol>
                {selected.techStack &&
                  selected.techStack.map(tech => <li key={tech}>{tech}</li>)}
              </ol>
            </div>
          </div>
          <hr />
          <button
            className="btn btn-primary w-100"
            data-toggle="modal"
            data-target="#exampleModalCenter"
            disabled={
              selected.status !== "in-review" ||
              selected.recommendations.length == 0
            }
            hidden={
              selected.status !== "in-review" && selected.status !== "pending"
            }
            onClick={openModal}
          >
            View Recommendations
          </button>
          <button className="btn btn-outline-dark mt-3 w-100">
            Submit New Request
          </button>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Recommendations Modal"
        style={customStyles}
      >
        <div className="row">
          <h3 className="w-50 pl-4 modal-title" id="exampleModalCenterTitle">
            Recommendations
          </h3>
          <div className="w-50 pr-4">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={closeModal}
            >
              <span className="fa-2x">&times;</span>
            </button>
          </div>
        </div>
        <hr />
        <div className="modal-body">
          <div className="row">
            <div className="col-6 fix">
              <img
                className="d-block rounded"
                src={currentRecommendation && currentRecommendation.pp}
                style={{ height: "300px", width: "300px" }}
                alt="First slide"
              />
            </div>
            <div className="col-6">
              <p className="profile-name text-center">
                {currentRecommendation && currentRecommendation.name}
              </p>
              <a
                className="btn btn-primary w-100"
                href={currentRecommendation && currentRecommendation.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Profile
              </a>
              <a
                className="btn btn-outline-dark w-100 mt-2"
                href={currentRecommendation && currentRecommendation.calendly}
                target="_blank"
                rel="noopener noreferrer"
              >
                Setup Interview
              </a>
              <button
                className="btn btn-outline-success w-100 mt-2"
                onClick={() => {
                  acceptResource(selected.recommendations[rindex]);
                }}
              >
                Accept
              </button>
              <button
                className="btn btn-outline-danger w-100 mt-2"
                onClick={() => {
                  rejectResource(selected.recommendations[rindex]);
                }}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              if (selected.recommendations.length > 0) {
                if (rindex == 0) {
                  setRindex(() => {
                    setCurrentRecommendation(
                      recommendations[
                        selected.recommendations[
                          selected.recommendations.length - 1
                        ]
                      ]
                    );
                    return selected.recommendations.length - 1;
                  });
                } else {
                  setRindex(() => {
                    setCurrentRecommendation(
                      recommendations[selected.recommendations[rindex - 1]]
                    );
                    return rindex - 1;
                  });
                }
              }
            }}
          >
            Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              if (selected.recommendations.length > 0) {
                if (rindex == selected.recommendations.length - 1) {
                  setRindex(() => {
                    setCurrentRecommendation(
                      recommendations[selected.recommendations[0]]
                    );
                    return 0;
                  });
                } else {
                  setRindex(() => {
                    setCurrentRecommendation(
                      recommendations[selected.recommendations[rindex + 1]]
                    );
                    return rindex + 1;
                  });
                }
              }
            }}
          >
            Next
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Requests;
