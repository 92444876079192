import { firestore } from "../firebase";

export const storeObject = async (collection, object) => {
  await firestore
    .collection(collection)
    .add(object)
    .catch(() => {
      return false;
    });
  return true;
};

export const storeObjectWithId = async (collection, doc, object) => {
  const obj = await firestore
    .collection(collection)
    .doc(doc)
    .set(object)
    .catch(e => {
      console.error(e);
      return false;
    });
  console.log(obj);
  return true;
};

export const retrieveObject = async (collection, document) => {
  const docRef = await firestore.collection(collection).doc(document);
  const data = await docRef
    .get()
    .then(doc => {
      if (!doc.exists) return false;
      return doc.data();
    })
    .catch(() => {
      return false;
    });
  return data;
};
