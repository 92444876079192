const statusMessages = {
  pending:
    "We are currently generating recommendations for you. You will be notified once a list of professionals has been curated according to your requirements.",
  "in-review":
    "We have found a possible fit for your job posting. You can review the recommendations by clicking on the button below.",
  done:
    "Congratulations on hiring the perfect match! We hope you had a great experience of hiring through us. We would love to hear your feedback. Leave us a rating below. Thank you for using Teamo.",
  checkout:
    "Almost there. Please add your billing information by clicking on the setup billing button in the section below."
};

export default statusMessages;
