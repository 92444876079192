import React, { useState, useContext, useEffect } from "react";
import Requests from "./components/Requests";
import logo from "./images/logo.png";
import { auth } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import { retrieveObject } from "./utilities/db";
import { generateMagicLink, initializeCustomer } from "./utilities/api";
import Modal from "react-modal";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";

const Dashboard = () => {
  const user = useContext(UserContext);
  const [customer, setCustomer] = useState(false);
  const [isBilling, setIsBilling] = useState(false);
  const [billingLink, setBillingLink] = useState("");
  const [customerName, setCustomerName] = useState("");
  useEffect(() => {
    if (customer) {
      setIsBilling(true);
    }
    (async () => {
      const link = await generateMagicLink(user);
      setBillingLink(link);
    })();
  }, [customer, user]);
  useEffect(() => {
    (async () => {
      setCustomer(await retrieveObject("customers", user && user.uid));
    })();
  }, [user]);
  let [modalIsOpen, setIsOpen] = useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <AuthenticatedRoute>
      <div className="full-page mx-4">
        <div className="container-fluid d-flex">
          <h1 className="w-50">
            <img className="logo mt-3" src={logo} alt="logo" />
          </h1>
          <div className="w-50 text-right">
            <button
              className="btn btn-outline-dark border rounded-circle shadow-sm mt-2 mr-3 profile-picture"
              onClick={() => {
                auth.signOut();
              }}
            >
              <i className="fa fa-sign-out-alt fa-2x"></i>
            </button>
          </div>
        </div>
        <div className="container mt-5">
          <div className="row">
            <Requests />
          </div>
          <div className="row">
            <div className="card shadow mt-3 mr-lg-1 bottom-card col-12 col-md-5 col-lg-3">
              <div className="card-body">
                <h3 className="card-title font-weight-bold">Billing</h3>
                <hr />
                <div className="m-auto d-flex" style={{ height: "12rem" }}>
                  <button
                    className="btn btn-primary m-auto w-100"
                    onClick={() => {
                      openModal();
                    }}
                    hidden={isBilling}
                  >
                    Setup Billing
                  </button>
                  <button
                    className="btn btn-primary m-auto w-100"
                    onClick={() => {
                      window.open(billingLink, "_blank");
                    }}
                    hidden={!isBilling}
                  >
                    View Billing
                  </button>
                </div>
              </div>
            </div>
            <div className="card shadow mt-3 ml-lg-1 bottom-card col-12 col-md-5 col-lg-3">
              <div className="card-body">
                <h3 className="card-title font-weight-bold">Contracts</h3>
                <hr />
                <div
                  className="d-flex m-auto text-center"
                  style={{ height: "12rem" }}
                >
                  <p className="card-text m-auto">
                    You currently have no pending contracts.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 mb-5">
            <button className="m-auto btn btn-outline-dark">
              View Pricing Details
            </button>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalCenterTitle">
                  Recommendations
                </h3>
                <hr />
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-6 fix">
                    <img
                      className="d-block w-100 rounded"
                      src="./images/pp.jpg"
                      alt="First slide"
                    />
                    <i className="d-block w-100 rounded fa fa-gear"></i>
                  </div>
                  <div className="col-6">
                    <p className="profile-name text-center">Profile ID A01</p>
                    <button className="btn btn-primary w-100">
                      View Profile
                    </button>
                    <button className="btn btn-outline-dark w-100 mt-2">
                      Setup Interview
                    </button>
                    <button className="btn btn-outline-success w-100 mt-2">
                      Accept
                    </button>
                    <button className="btn btn-outline-danger w-100 mt-2">
                      Reject
                    </button>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Back
                </button>
                <button type="button" className="btn btn-primary">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Billing Modal"
          style={customStyles}
        >
          <form
            onSubmit={async e => {
              e.preventDefault();
              await initializeCustomer(customerName, user && user.uid);
              setCustomer(await retrieveObject("customers", user && user.uid));
              const link = await generateMagicLink();
              setBillingLink(link);
              closeModal();
            }}
          >
            <label htmlFor="customerName">Enter your name</label>
            <input
              className="input-group-text my-2"
              type="text"
              value={customerName}
              onChange={e => {
                setCustomerName(e.target.value);
              }}
            />
            <button
              className="btn btn-primary rounded w-100"
              disabled={customerName.length === 0}
            >
              Submit
            </button>
          </form>
        </Modal>
      </div>
    </AuthenticatedRoute>
  );
};

export default Dashboard;
