import React, { useState, useContext, useRef } from "react";
// import Calendly from "./components/Calendly";
import { firestore } from "./firebase";
import { UserContext } from "./providers/UserProvider";
import { navigate } from "@reach/router";
import { RequestsContext } from "./providers/RequestsProviderFunctional";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthenticatedRoute } from "./components/AuthenticatedRoute";

function openInNewTab(url) {
  var win = window.open(url, "_blank");
  win.focus();
}

function validationError(ref, errorMessage) {
  const toastError = () =>
    toast(errorMessage, {
      className: "toast-background rounded",
      progressClassName: "toast-progress"
    });
  toastError();
  ref.current.classList.add("border-danger", "border-validator");
  setTimeout(() => {
    ref.current.classList.remove("border-danger", "border-validator");
  }, 5000);
}

const RequestForm = () => {
  let [steps, setSteps] = useState([1, 0, 0, 0]);
  let [resourceType, setResourceType] = useState([
    { resource: "", experience: "", number: undefined }
  ]);
  let [projectDescription, setProjectDescription] = useState("");
  let [tech, setTech] = useState("JavaScript");
  let [techStack, setTechStack] = useState([]);
  let [timeOverlap, setTimeOverlap] = useState(null);
  let [overlapHours, setOverlapHours] = useState(0);
  let [businessStartTime, setBusinessStartTime] = useState("");
  let [businessStartTimePostfix, setBusinessStartTimePostfix] = useState("am");
  let [businessEndTime, setBusinessEndTime] = useState("");
  let [businessEndTimePostfix, setBusinessEndTimePostfix] = useState("pm");
  let [timezone, setTimezone] = useState("");
  let resourceQuestion = useRef();
  let projectQuestion = useRef();
  let techQuestion = useRef();
  let overlapQuestion = useRef();
  let businessTimeQuestion = useRef();
  // let [meet, setMeet] = useState(null);
  const user = useContext(UserContext);
  const requests = useContext(RequestsContext);

  function submitRequest() {
    firestore
      .collection("requests")
      .doc()
      .set({
        rid: requests.length + 1,
        client: user.uid,
        timezone,
        businessStartTime,
        businessEndTime,
        techStack,
        resourceType,
        timeOverlap,
        overlapHours,
        projectDescription,
        status: "pending",
        recommendations: [],
        team: []
      });
  }
  return (
    <AuthenticatedRoute>
      <div className="row overflow-hidden full-page w-100">
        <div className="w-100 close-form" style={{ position: "absolute" }}>
          <i
            className="fa fa-times fa-2x cursor-pointer"
            role="button"
            onClick={() => {
              navigate("/dashboard");
            }}
            onKeyUp={() => {}}
            tabIndex="0"
          ></i>
        </div>
        <div
          id="hiring-details"
          className="col-12 col-md-6 m-auto text-center"
          hidden={steps[0] !== 1}
        >
          <div id="resource-types" className="question" ref={resourceQuestion}>
            <h3>What types of resources do you want to hire?</h3>
            {/* <p>Select all that apply</p> */}
            {resourceType.map((value, index) => (
              <div className="row m-auto" key={index}>
                <div className="d-flex m-auto">
                  <select
                    className="number-input m-1"
                    name="types"
                    value={value.resource}
                    onChange={e => {
                      let current = resourceType;
                      current[index].resource = e.target.value;
                      setResourceType([...current]);
                    }}
                    onBlur={e => {
                      let current = resourceType;
                      current[index].resource = e.target.value;
                      setResourceType([...current]);
                    }}
                  >
                    <option value="">Select Type</option>
                    <option value="frontend">Front-end Developer</option>
                    <option value="backend">Back-end Developer</option>
                    <option value="fullstack">Fullstack Developer</option>
                    <option value="uiux">UI/UX Designer</option>
                    <option value="devops">DevOps Engineer</option>
                  </select>
                  <h1>|</h1>
                  <select
                    className="number-input m-1"
                    name="experience"
                    value={value.experience}
                    onBlur={e => {
                      resourceType[index].experience = e.target.value;
                      resourceType[index].number = 1;
                      setResourceType([...resourceType]);
                    }}
                    onChange={e => {
                      resourceType[index].experience = e.target.value;
                      resourceType[index].number = 1;
                      setResourceType([...resourceType]);
                    }}
                  >
                    <option value="">Select Experience</option>
                    <option value="junior">Junior</option>
                    <option value="mid">Mid-level</option>
                    <option value="senior">Senior</option>
                  </select>
                  <h1>|</h1>
                  <input
                    className="number-input m-1"
                    type="number"
                    value={value.number}
                    placeholder="No. of personnel"
                    onChange={e => {
                      resourceType[index].number = e.target.value;
                      setResourceType([...resourceType]);
                    }}
                  />
                  <i
                    className="fa fa-times m-auto"
                    role="button"
                    tabIndex="-1"
                    onClick={() => {
                      resourceType.splice(index, 1);
                      setResourceType([...resourceType]);
                    }}
                    onKeyUp={() => {}}
                  ></i>
                </div>
              </div>
            ))}
            <div id="formlist"></div>
            <div className="row m-auto">
              <div className="col-12">
                <div
                  role="button"
                  tabIndex="-1"
                  className="btn btn-outline-dark px-3 mt-3"
                  onClick={() => {
                    resourceType.push({
                      resource: "",
                      experience: "",
                      number: undefined
                    });
                    setResourceType([...resourceType]);
                  }}
                  onKeyUp={() => {
                    resourceType.push({
                      resource: "",
                      experience: "",
                      number: undefined
                    });
                    setResourceType([...resourceType]);
                  }}
                >
                  <i className="fa fa-plus"></i>
                </div>
              </div>
            </div>
            {/* <div className="btn-group-vertical w-50">
              <button
                id="front"
                className="btn btn-outline-dark"
                onClick={() => {
                  toggleResourceType("#front-check", "frontend");
                }}
              >
                Front-end Developer{" "}
                <i id="front-check" className="fa fa-check" hidden></i>
              </button>
              <button
                className="btn btn-outline-dark"
                onClick={() => {
                  toggleResourceType("#back-check", "backend");
                }}
              >
                Back-end Developer{" "}
                <i id="back-check" className="fa fa-check" hidden></i>
              </button>
              <button
                className="btn btn-outline-dark"
                onClick={() => {
                  toggleResourceType("#fullstack-check", "fullstack");
                }}
              >
                Fullstack Developer{" "}
                <i id="fullstack-check" className="fa fa-check" hidden></i>
              </button>
              <button
                className="btn btn-outline-dark"
                onClick={() => {
                  toggleResourceType("#uiux-check", "uiux");
                }}
              >
                UI/UX Designer{" "}
                <i id="uiux-check" className="fa fa-check" hidden></i>
              </button>
              <button
                className="btn btn-outline-dark"
                onClick={() => {
                  toggleResourceType("#devops-check", "devops");
                }}
              >
                DevOps Engineer{" "}
                <i id="devops-check" className="fa fa-check" hidden></i>
              </button>
            </div> */}
          </div>
          {/* <div className="question mt-4" ref={resourceNumberQuestion}>
            <h3>How many people do you want to hire?</h3>
            <input
              type="number"
              min="0"
              className="number-input"
              value={numberOfResources}
              onChange={e => setNumberOfResources(e.target.value)}
            />
          </div> */}
          <hr />
          <div className="row container">
            <div className="col-6 text-left">
              <button className="btn btn-light" disabled>
                Back
              </button>
            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-primary"
                id="hiring-done"
                // disabled={resourceType.length == 0 || numberOfResources <= 0}
                onClick={() => {
                  let valid = true;
                  if (resourceType.length === 0) {
                    validationError(
                      resourceQuestion,
                      "You must add atleast one resource"
                    );
                    valid = false;
                  } else {
                    resourceType.forEach(r => {
                      if (
                        r.resource === "" ||
                        r.number === undefined ||
                        r.number === 0 ||
                        r.experience === ""
                      ) {
                        valid = false;
                        validationError(
                          resourceQuestion,
                          "Please fill the resource information"
                        );
                      }
                    });
                  }
                  valid && setSteps([0, 1, 0, 0]);
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div
          id="project-details"
          hidden={steps[1] !== 1}
          className="col-12 col-md-6 m-auto text-center"
        >
          <div className="question" ref={projectQuestion}>
            <h3>Please tell us about your project.</h3>
            <textarea
              placeholder="A small description of your project..."
              name="project-description"
              id="details"
              cols="40"
              rows="5"
              className="rounded"
              value={projectDescription}
              onChange={e => setProjectDescription(e.target.value)}
            >
              {projectDescription}
            </textarea>
          </div>
          <div className="question mt-4" ref={techQuestion}>
            <h3>What does your technology stack look like?</h3>
            <input
              type="text"
              id="stack"
              className="number-input"
              value={tech}
              onChange={e => setTech(e.target.value)}
              onKeyUp={e => {
                if (e.keyCode == "13" && tech !== "") {
                  setTechStack([...techStack, tech]);
                  setTech("");
                }
              }}
            />
            <button
              className="btn btn-dark ml-1 mt-1"
              id="add-tech"
              onClick={() => {
                if (tech !== "") {
                  setTechStack([...techStack, tech]);
                  setTech("");
                }
              }}
            >
              Add
            </button>
            <div id="labels" className="">
              {techStack.map(technology => (
                <label
                  id={technology}
                  className="label"
                  key={technology}
                  role="presentation"
                  onClick={e => {
                    let index = techStack.indexOf(e.target.id);
                    if (index > -1) {
                      techStack.splice(index, 1);
                      setTechStack([...techStack]);
                    }
                  }}
                  onKeyUp={() => {}}
                >
                  {technology}
                </label>
              ))}
            </div>
          </div>
          <hr />
          <div className="row container">
            <div className="col-6 text-left">
              <button
                className="btn btn-light"
                id="hiring-back"
                onClick={() => {
                  setSteps([1, 0, 0, 0]);
                }}
              >
                Back
              </button>
            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-primary"
                id="project-done"
                onClick={() => {
                  if (projectDescription.length === 0) {
                    validationError(
                      projectQuestion,
                      "Enter a small description of your project"
                    );
                  } else if (projectDescription.split(" ").length < 5) {
                    validationError(
                      projectQuestion,
                      "The description should be at least 5 words"
                    );
                  }
                  if (techStack.length === 0) {
                    validationError(
                      techQuestion,
                      "At least enter 1 technology"
                    );
                  }
                  if (
                    projectDescription.split(" ").length >= 5 &&
                    techStack.length > 0
                  ) {
                    setSteps([0, 0, 1, 0]);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div
          id="operations-details"
          className="col-12 col-md-6 m-auto text-center"
          hidden={steps[2] !== 1}
        >
          <div className="question" ref={overlapQuestion}>
            <h3 id="overlap">Do you need a time overlap with your team?</h3>
            <button
              className="btn btn-light mr-2 pr-4"
              id="overlap-exists"
              onClick={() => {
                document.querySelector("#overlap").style["text-decoration"] =
                  "none";
                setTimeOverlap(true);
              }}
            >
              Yes <i id="overlap-yes-check"></i>
            </button>
            <button
              className="btn btn-light pr-4"
              id="overlap-none"
              onClick={() => {
                document.querySelector("#overlap").style["text-decoration"] =
                  "line-through";
                setTimeOverlap(false);
                setOverlapHours(0);
              }}
            >
              No <i id="overlap-no-check"></i>
            </button>
          </div>
          <div id="overlap" className="question mt-4" hidden={!timeOverlap}>
            <h3>How many hours of overlap do you need?</h3>
            <input
              min="0"
              type="number"
              className="number-input"
              value={overlapHours}
              onChange={e => setOverlapHours(e.target.value)}
            />
          </div>
          <div className="question mt-4" ref={businessTimeQuestion}>
            <h3>What are your regular business hours?</h3>
            <label className="label">
              Start Time:
              <select
                name="startTime"
                className="number-input"
                value={businessStartTime}
                onChange={e => setBusinessStartTime(e.target.value)}
                onBlur={e => setBusinessStartTime(e.target.value)}
              >
                <option value="">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <select
                name="startTime"
                className="number-input ml-2"
                value={businessStartTimePostfix}
                onChange={e => setBusinessStartTimePostfix(e.target.value)}
                onBlur={e => setBusinessStartTimePostfix(e.target.value)}
              >
                <option value="am">am</option>
                <option value="pm">pm</option>
              </select>
              {/* <input
              type="text"
              placeholder="0900"
              className="number-input"
              value={businessStartTime}
              onChange={e => setBusinessStartTime(e.target.value)}
            /> */}
            </label>
            <label className="label">
              End Time:
              <select
                name="endTime"
                className="number-input"
                value={businessEndTime}
                onChange={e => setBusinessEndTime(e.target.value)}
                onBlur={e => setBusinessEndTime(e.target.value)}
              >
                <option value="">Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <select
                name="startTime"
                className="number-input ml-2"
                value={businessEndTimePostfix}
                onChange={e => setBusinessEndTimePostfix(e.target.value)}
                onBlur={e => setBusinessEndTimePostfix(e.target.value)}
              >
                <option value="pm">pm</option>
                <option value="am">am</option>
              </select>
              {/* <input
              type="text"
              placeholder="1700"
              className="number-input"
              value={businessEndTime}
              onChange={e => setBusinessEndTime(e.target.value)}
            /> */}
            </label>
            <br />
            <label className="label">
              Time Zone
              <select
                className="number-input"
                id=""
                value={timezone}
                onChange={e => setTimezone(e.target.value)}
                onBlur={e => setTimezone(e.target.value)}
              >
                <option value="">Select GMT</option>
                <option value="-12">-12</option>
                <option value="-11">-11</option>
                <option value="-10">-10</option>
                <option value="-9">-9</option>
                <option value="-8">-8</option>
                <option value="-7">-7</option>
                <option value="-6">-6</option>
                <option value="-5">-5</option>
                <option value="-4">-4</option>
                <option value="-3">-3</option>
                <option value="-2">-2</option>
                <option value="-1">-1</option>
                <option value="0">0</option>
                <option value="1">+1</option>
                <option value="2">+2</option>
                <option value="3">+3</option>
                <option value="4">+4</option>
                <option value="5">+5</option>
                <option value="6">+6</option>
                <option value="7">+7</option>
                <option value="8">+8</option>
                <option value="9">+9</option>
                <option value="10">+10</option>
                <option value="11">+11</option>
                <option value="12">+12</option>
              </select>
            </label>
          </div>
          <hr />
          <div className="row container">
            <div className="col-6 text-left">
              <button
                className="btn btn-light"
                id="project-back"
                onClick={() => setSteps([0, 1, 0, 0])}
              >
                Back
              </button>
            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-primary"
                id="operations-done"
                onClick={() => {
                  if (
                    timeOverlap === null ||
                    timezone === "" ||
                    businessStartTime === "" ||
                    businessEndTime === "" ||
                    businessEndTimePostfix === "" ||
                    businessStartTimePostfix === ""
                  ) {
                    if (timeOverlap === null) {
                      validationError(
                        overlapQuestion,
                        "Please select if you need a time overlap"
                      );
                    }
                    if (
                      timezone === "" ||
                      businessStartTime === "" ||
                      businessEndTime === "" ||
                      businessEndTimePostfix === "" ||
                      businessStartTimePostfix === ""
                    ) {
                      validationError(
                        businessTimeQuestion,
                        "Fill the business operation hours details"
                      );
                    }
                  } else {
                    setSteps([0, 0, 0, 1]);
                    submitRequest();
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div
          id="meeting"
          hidden={steps[3] !== 1}
          className="col-12 col-md-6 m-auto text-center"
        >
          <div className="question my-2">
            <h3>Would you like to setup a meeting?</h3>
            <button
              className="btn btn-primary mr-2 pr-5"
              id="meeting-yes"
              onClick={() => {
                openInNewTab(
                  `https://calendly.com/teamo/teamo?name=${user &&
                    user.displayName}&email=${user && user.email}`
                );
                // setMeet(true);
                navigate("/dashboard");
              }}
            >
              Yes <i id="meeting-yes-check"></i>
            </button>
            <button
              className="btn btn-light pr-5"
              id="meeting-no"
              onClick={() => {
                navigate("/dashboard");
              }}
            >
              No <i id="meeting-no-check"></i>
            </button>
          </div>
          {/* <div className="question mt-4" hidden={!meet} id="meeting-link">
          <h3>Schedule a meeting</h3>
          <Calendly
            src={`https://calendly.com/teamo/teamo?name=${user &&
              user.displayName}&email=${user && user.email}`}
          />
        </div> */}
        </div>
        {/* <!-- <div className="col-md-6 shape d-none d-xl-block"></div> --> */}
      </div>
    </AuthenticatedRoute>
  );
};

export default RequestForm;
