import React from "react";
import { render } from "react-dom";
import SignUp from "./SignUp";
import { Router } from "@reach/router";
import SignIn from "./SignIn";
import Dashboard from "./Dashboard";
import RequestForm from "./RequestForm";
import UserProvider from "./providers/UserProvider";
import RequestsProvider from "./providers/RequestsProviderFunctional";
import Modal from "react-modal";

Modal.setAppElement("#root");
const App = () => {
  return (
    <UserProvider>
      <RequestsProvider>
        <div className="h-screen main">
          <Router>
            <SignUp path="/sign-up" />
            <SignIn path="/" />
            <Dashboard path="/dashboard" />
            <RequestForm path="/submit-request" />
          </Router>
        </div>
      </RequestsProvider>
    </UserProvider>
  );
};

render(<App />, document.getElementById("root"));
